
import { Component, Vue, Inject } from "vue-property-decorator";
import TenantSwitch from "../components/tenant-switch.vue";
import TenantSwitchModern from "../components/tenant-switch-modern.vue";
import LanguageSwitch from "../components/language-switch.vue";
import AbpBase from "../lib/abpbase";
import { PublicClientApplication } from "@azure/msal-browser";

import DefaultHeader from "../components/Muse/Headers/DefaultHeader.vue";
import DefaultFooter from "../components/Muse/Footers/DefaultFooter.vue";

@Component({
  components: {
    TenantSwitch,
    LanguageSwitch,
    DefaultHeader,
    DefaultFooter,
    TenantSwitchModern,
  },
})
export default class Login extends AbpBase {
  loginModel = {
    userNameOrEmailAddress: "",
    password: "",
    rememberMe: false,
  };

  form: any;
  loading: boolean = false;
  account: any;
  greeting: String;

  showChangeTenant: boolean = false;
  async login() {
    this.loading = true;
    await this.$store
      .dispatch({
        type: "app/login",
        data: this.loginModel,
      })
      .then(() => {
        sessionStorage.setItem(
          "rememberMe",
          this.loginModel.rememberMe ? "1" : "0"
        );
        //this.loading = false;
        location.reload();
      })
      .catch(() => {
        this.loading = false;
      });
  }
  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }
  get tenant() {
    if (this.$store.state.session.tenant == null) {
      return "";
    } else {
      return this.$store.state.session.tenant;
    }
  }

  get headerImage() {
    if (this.$store.state.session.tenant == null) {
      return "img/Group-6.png";
    } else if (
      this.$store.state.session.tenant &&
      this.$store.state.session.tenant.name == "People"
    ) {
      return "img/Group-6.png";
    } else {
      return "img/PG.png";
    }
  }

  changeTenant() {
    this.showChangeTenant = true;
  }
  get layoutClass() {
    return this.$route.meta.layoutClass;
  }
  async ADlogin() {
    this.loading = true;
    await this.$store.state.account.msalInstance
      .loginPopup({})
      .then(async () => {
        const myAccounts =
          this.$store.state.account.msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        await this.$store.dispatch({
          type: "app/login",
          data: {
            userNameOrEmailAddress: this.account.username,
            password: "none",
            rememberMe: false,
          },
        });
        //this.loading = false;
        location.reload();
      })
      .catch((error) => {
        console.error(`error during authentication: ${error}`);
        this.loading = false;
      });
  }
  beforeCreate() {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      this.greeting = "Buenos Días!";
    } else if (curHr < 20) {
      this.greeting = "Buenas Tardes!";
    } else {
      this.greeting = "Buenas Noches!";
    }
  }
  created() {
    this.$store.state.account.msalInstance = new PublicClientApplication(
      this.$store.state.app.msalConfig
    );
  }
}
