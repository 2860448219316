
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../lib/util";
import AbpBase from "../lib/abpbase";
import Modal from "./Modal.vue";
import AppConsts from "../lib/appconst";
@Component({
  components: { Modal },
})
export default class TenantSwitchModern extends AbpBase {
  @Prop({ default: false }) value: boolean;
  changedTenancyName: any = new Object();
  tenants = [
    { name: AppConsts.defaultTenant, tenancyName: "Default" },
    { name: AppConsts.procterGamble, tenancyName: "ProcterGamble" },
  ];
  get tenant() {
    return this.$store.state.session.tenant;
  }
  cancel() {
    this.$emit("input", false);
  }
  visibleChange(value: boolean) {
    if (!value) {
      this.$emit("input", value);
    } else {
      this.changedTenancyName = this.$store.state.session.tenant
        ? this.$store.state.session.tenant
        : this.tenants.find((x) => x.tenancyName === "Default");
    }
  }
  checkFormValidity() {
    const valid = (this.$refs.tenantForm as any).checkValidity();
    return valid;
  }

  setTenant(t){
    this.changedTenancyName = t;
    this.changeTenant();
  }

  async changeTenant() {
    if (!this.changedTenancyName.tenancyName) {
      Util.abp.multiTenancy.setTenantIdCookie(undefined);
      location.reload();
      return;
    } else {
      let tenant = await this.$store.dispatch({
        type: "account/isTenantAvailable",
        data: { tenancyName: this.changedTenancyName.tenancyName },
      });
      switch (tenant.state) {
        case 1:
          Util.abp.multiTenancy.setTenantIdCookie(tenant.tenantId);
          location.reload();
          return;
        case 2:
          //this.$Modal.error({title:this.L('Error'),content:this.L('TenantIsNotActive')});
          break;
        case 3:
          //this.$Modal.error({title:this.L('Error'),content:this.L('ThereIsNoTenantDefinedWithName{0}',undefined,this.changedTenancyName)});
          break;
      }
    }
  }
  tenantRule = {
    name: {
      required: { required: true },
      placeholder: this.L("Tenant"),
      trigger: "blur",
    },
  };
}
